import { graphql, Link, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";
import LogoWithText from "./LogoWithText";
import Owl from "./Owl";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { de: { template: { eq: "footer" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                slogan
                copyright
                address
                email
                website
                linkedinUrl
              }
              en {
                slogan
                copyright
                address
              }
            }
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const footer = markdownUnwrapper(data, language)[0];
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <a href="/" className="logo d-block">
                <LogoWithText width="7rem" height="100%" />
              </a>
              <p>
                <DangerousMarkdownContent content={footer.slogan} />
              </p>
              <ul className="social-link">
                <li>
                  <a
                    href={footer.website}
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bx-globe"></i>
                  </a>
                </li>
                <li>
                  <a href={`mailto:${footer.email}`} className="d-block">
                    <i className="bx bx-envelope"></i>
                  </a>
                </li>
                <li>
                  <a
                    href={footer.linkedinUrl}
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>{t("footer:explore")}</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/">{t("navigation:home")}</Link>
                </li>
                <li>
                  <Link to="/about-us">{t("navigation:about-us")}</Link>
                </li>
                <li>
                  <Link to="/team">{t("navigation:team")}</Link>
                </li>
                <li>
                  <Link to="/contact">{t("navigation:contact")}</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              <h3>{t("footer:resources")}</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <h3>{t("common:address")}</h3>

              <ul className="footer-contact-info">
                <li>
                  <i className="bx bx-map"></i>
                  <DangerousMarkdownContent content={footer.address} />
                </li>
                {/*<li>*/}
                {/*  <i className="bx bx-phone-call"></i>*/}
                {/*  <a href="tel:+44587154756">+1 (123) 456 7890</a>*/}
                {/*</li>*/}
                <li>
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:info@timewise.ch">info@timewise.ch</a>
                </li>
                {/*<li>*/}
                {/*  <i className="bx bxs-inbox"></i>*/}
                {/*  <a href="tel:+557854578964">+55 785 4578964</a>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                Copyright @{currentYear} <strong>timewise AG</strong>
                &nbsp;
                <DangerousMarkdownContent
                  className="d-inline-block"
                  content={footer.copyright}
                />
                &nbsp;
                <a
                  target="_blank"
                  href="https://www.timewise.ch/"
                  rel="noreferrer"
                >
                  timewise
                </a>
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <ul>
                {/*<li>*/}
                {/*  <Link to="/privacy-policy">Privacy Policy</Link>*/}
                {/*</li>*/}
                <li>
                  <Link to="/contact">{t("footer:legal-notice")}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <p style={{ fontSize: "0.8rem" }}>
                {t("footer:icons")}{" "}
                <a
                  href="https://www.flaticon.com/authors/pixel-perfect"
                  title="Pixel perfect"
                >
                  Pixel perfect
                </a>{" "}
                {t("common:from")}{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  Flaticon
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-map">
        <Owl className="footer-background-img" width="75%" height="70%" />
      </div>
    </footer>
  );
};

export default Footer;
