import React from "react";

const Owl = ({ className, width = "1em", height = "1em" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 210 297"
      width={width}
      height={height}
      fill="currentColor"
    >
      <defs id="defs900">
        <clipPath id="clipPathOwl">
          <path d="M 0,99.763 H 79.492 V 0 H 0 Z" id="path4" />
        </clipPath>
      </defs>
      <g id="layer1">
        <g
          transform="matrix(6.1659506,0,0,-6.1659506,-141.16059,456.74838)"
          id="g31"
        >
          <g clipPath="url(#clipPathOwl)" id="g29">
            <g transform="translate(37.26,49.782)" id="g11">
              <path
                d="m 0,0 c -0.128,0.032 -0.253,0.067 -0.379,0.103 -3.676,1.048 -6.622,3.821 -7.911,7.388 -0.009,0.025 -0.018,0.051 -0.027,0.076 -0.076,0.162 -0.239,0.274 -0.429,0.274 -0.17,0 -0.307,-0.086 -0.391,-0.219 -0.026,-0.042 -0.047,-0.09 -0.069,-0.141 -0.983,-2.295 -1.53,-4.823 -1.53,-7.479 0,-0.71 0.04,-1.411 0.116,-2.1 0.279,-2.54 1.061,-4.927 2.24,-7.065 h -0.002 c 1.841,-3.176 2.27,-6.077 2.014,-8.66 -0.233,-2.36 -1.646,-3.777 -1.646,-3.777 0,0 -0.324,-0.556 0.211,-0.783 0,0 0.068,-0.041 0.155,-0.077 0.094,-0.037 0.187,-0.078 0.283,-0.114 0.007,-0.002 0.013,-0.005 0.02,-0.008 0.08,-0.028 0.16,-0.057 0.241,-0.084 1.205,-0.412 2.497,-0.636 3.84,-0.636 3.742,0 7.077,1.731 9.253,4.433 1.643,2.038 2.627,4.627 2.627,7.447 0,1.264 -0.199,2.481 -0.564,3.623 C 6.847,-4.034 3.815,-1.088 0,0"
                fill="currentColor"
                id="path9"
              />
            </g>
            <g transform="translate(51.598,52.637)" id="g15">
              <path
                d="m 0,0 c -0.012,0.03 -0.027,0.059 -0.04,0.088 -0.08,0.149 -0.232,0.251 -0.413,0.251 -0.138,0 -0.252,-0.055 -0.339,-0.148 -0.019,-0.025 -0.046,-0.054 -0.064,-0.079 -2.392,-3.179 -3.81,-7.131 -3.81,-11.415 0,-2.54 0.5,-4.963 1.404,-7.178 0.009,-0.03 0.033,-0.068 0.047,-0.096 0.081,-0.149 0.225,-0.242 0.405,-0.242 0.115,0 0.304,0.081 0.39,0.205 0.002,0.002 0.004,0.005 0.006,0.008 2.397,3.181 3.819,7.138 3.819,11.428 C 1.405,-4.638 0.904,-2.215 0,0"
                fill="currentColor"
                id="path13"
              />
            </g>
            <g transform="translate(50.318,73.216)" id="g19">
              <path
                d="m 0,0 c -0.057,0 -0.109,-0.012 -0.159,-0.029 -0.052,-0.032 -0.104,-0.061 -0.155,-0.092 -2.87,-1.722 -6.226,-2.716 -9.817,-2.716 -3.611,0 -6.985,1.005 -9.866,2.746 -0.077,0.047 -0.155,0.093 -0.232,0.141 -0.039,0.01 -0.079,0.017 -0.12,0.017 -0.264,0 -0.478,-0.214 -0.478,-0.478 0,-0.04 0.007,-0.078 0.016,-0.115 0.033,-0.064 0.064,-0.127 0.098,-0.19 0.751,-1.42 1.779,-2.67 3.014,-3.677 l 0.07,-0.002 c 2.024,-0.088 3.827,-1.016 5.075,-2.442 0.781,-0.892 1.343,-1.978 1.605,-3.178 -0.524,-0.185 -0.963,-0.546 -1.247,-1.014 l 0.003,-0.003 c -0.004,-0.006 -0.008,-0.011 -0.012,-0.017 0.517,-0.437 0.97,-0.946 1.346,-1.511 0.276,-0.416 0.509,-0.862 0.695,-1.334 0.145,0.37 0.32,0.724 0.521,1.06 0.002,0.003 0.004,0.006 0.005,0.009 0.068,0.112 0.138,0.221 0.211,0.329 0.367,0.54 0.807,1.026 1.303,1.447 -10e-4,0.002 -0.003,0.005 -0.005,0.007 0.005,0.004 0.009,0.009 0.014,0.013 -0.276,0.454 -0.698,0.808 -1.201,0.997 0.261,1.213 0.83,2.31 1.62,3.208 1.238,1.408 3.018,2.327 5.015,2.428 l 0.066,0.001 c 1.219,0.994 2.236,2.224 2.985,3.619 l 0.085,0.161 c 0.013,0.044 0.023,0.089 0.023,0.137 C 0.478,-0.214 0.264,0 0,0"
                fill="currentColor"
                id="path17"
              />
            </g>
            <g transform="translate(47.751,64.514)" id="g23">
              <path
                d="m 0,0 c -0.073,0.048 -0.154,0.043 -0.226,0.06 -0.005,0.939 -0.768,1.698 -1.709,1.698 -0.944,0 -1.71,-0.765 -1.71,-1.709 0,-0.185 0.032,-0.361 0.086,-0.527 -1.037,-0.97 -0.511,-1.068 -0.511,-1.068 0.267,0.183 0.354,0.219 0.646,0.365 0.069,0.035 0.138,0.068 0.208,0.1 0.794,0.365 1.665,0.59 2.582,0.642 0.113,0.006 0.226,0.009 0.34,0.01 0.023,0 0.046,0.002 0.068,0.002 0,0 0.259,-0.029 0.315,0.102 0.064,0.152 0.006,0.247 -0.056,0.3 C 0.022,-0.015 0.011,-0.007 0,0"
                fill="currentColor"
                id="path21"
              />
            </g>
            <g transform="translate(32.536,64.514)" id="g27">
              <path
                d="m 0,0 c 0.074,0.048 0.154,0.043 0.227,0.06 0.005,0.939 0.768,1.698 1.709,1.698 0.944,0 1.709,-0.765 1.709,-1.709 0,-0.185 -0.032,-0.361 -0.086,-0.527 1.038,-0.97 0.511,-1.068 0.511,-1.068 -0.267,0.183 -0.353,0.219 -0.645,0.365 -0.07,0.035 -0.139,0.068 -0.209,0.1 -0.793,0.365 -1.664,0.59 -2.582,0.642 -0.113,0.006 -0.226,0.009 -0.339,0.01 -0.024,0 -0.046,0.002 -0.069,0.002 0,0 -0.258,-0.029 -0.314,0.102 -0.065,0.152 -0.006,0.247 0.055,0.3 C -0.021,-0.015 -0.011,-0.007 0,0"
                fill="currentColor"
                id="path25"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Owl;
