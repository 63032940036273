import React from "react";

const LogoWithText = ({ className, width = "1em", height = "100%" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 369.7 97.715"
      width={width}
      height={height}
      fill="currentColor"
    >
      <defs>
        <clipPath id="clipPath18">
          <path d="M 0,117.288 H 281.875 V 0 H 0 Z"></path>
        </clipPath>
      </defs>

      <g transform="matrix(1.7203 0 0 -1.7203 -57.754 155.23)">
        <g clipPath="url(#clipPath18)">
          <g transform="translate(54.862,58.799)">
            <path d="m0 0c-2.804 0-5.082 1.796-5.082 4.074 0 2.234 2.278 4.073 5.082 4.073 2.803 0 5.125-1.839 5.125-4.073 0-2.278-2.322-4.074-5.125-4.074m5.3-24.181h-8.717s-0.219 0.044-0.219 0.22c0 0 0.044 4.38 0.175 12.878 0 4.249-2.453 5.169-6.57 4.819v-9.812c0-0.527 0.043-0.964 0.087-1.403 0.569-3.547 3.285-2.759 4.994-2.759 0.788 0 0.876-0.57 0.569-1.139-1.182-2.365-4.468-3.986-7.622-3.986-1.401 0.044-2.759 0.35-3.898 1.008-1.796 1.094-2.979 3.11-2.979 5.87v12.221h-2.234s-0.176 0-0.176 0.219v3.723c0 0.176 0.176 0.176 0.176 0.176h2.234v3.329c0 0.218 0.219 0.218 0.219 0.218l8.411 3.461s0.219-0.044 0.175-0.219c0 0 0.044-3.066 0.044-6.789l8.717 0.043c3.548 0 6.746-2.453 6.746-6.571l0.044-15.244c0-0.219-0.176-0.263-0.176-0.263"></path>
          </g>
          <g transform="translate(101.47,36.853)">
            <path d="m0 0c-0.482-1.358-2.847-3.066-5.3-3.066-3.505 0-6.44 2.541-6.44 6.658v8.498c0 4.381-4.117 2.848-4.117-2.321v-0.79c0-2.846 0.35-10.951 0.35-10.951 0-0.218-0.219-0.262-0.219-0.262h-8.761s-0.219 0.044-0.175 0.219c0 0-0.088 8.148-0.044 10.994 0.044 1.052 0 2.147 0 3.111 0 4.381-4.118 2.848-4.118-2.321v-11.741c0-0.218-0.218-0.262-0.218-0.262h-8.762s-0.175 0.044-0.175 0.219c0 0 0.307 9.988 0.307 13.01 0 6.965-2.979 5.476-2.541 6.658 0.438 1.402 2.804 3.111 5.257 3.111 3.066 0 5.388-1.884 6.001-5.038 1.796 3.68 4.775 5.038 7.053 5.038 2.759 0 5.125-1.972 5.826-5.213 1.796 3.855 4.818 5.213 7.14 5.213 3.285 0 6.045-2.848 6.045-7.229v-6.833c0-6.965 3.329-5.475 2.891-6.702"></path>
          </g>
          <g transform="translate(109.12,47.016)">
            <path d="m0 0c3.898-0.351 5.826 1.095 5.826 3.46 0 1.183-0.964 2.19-2.322 2.102 0 0 0-0.042-0.044-0.042h-0.131c-0.307-0.044-0.701-0.176-1.051-0.395-1.446-0.876-2.103-2.979-2.278-5.125m13.755-6.352c-0.482-1.84-4.118-7.008-10.513-7.008-6.615 0-11.915 5.343-11.915 11.958 0 6.395 4.949 11.915 12.484 11.915 7.184 0 10.294-4.512 10.294-7.622 0-6.877-8.016-7.71-13.754-6.746 1.532-4.38 7.358-4.819 12.703-1.84 0.175 0.132 0.394 0.175 0.569 0.044s0.263-0.307 0.132-0.701"></path>
          </g>
          <g transform="translate(161.2,47.453)">
            <path d="m0 0c0-3.986-1.753-13.798-11.871-13.798h-0.263c-3.066 0-6.177 1.007-8.236 3.241-1.27-1.665-3.46-3.241-6.877-3.241-4.468 0-8.104 3.153-8.936 7.753-0.131 0.569-0.175 1.183-0.175 1.84v13.185c0 0.175 0.175 0.219 0.175 0.219h8.542s0.219-0.044 0.219-0.262v-14.15c0.131-2.628 1.182-3.548 2.365-3.548s2.366 1.227 2.366 4.205l0.044-0.044c-0.044 0.176-0.044 0.351-0.044 0.526h0.044v13.054c0 0.175 0.175 0.219 0.175 0.219h8.454s0.175-0.044 0.175-0.262v-13.493c0-2.846 0.351-4.205 1.796-4.205 1.84 0 3.812 1.928 3.812 6.921 0 7.798-3.812 7.973-3.812 8.893 0 0.832 2.103 3.11 5.345 3.11 3.372 0 6.702-2.628 6.702-10.163"></path>
          </g>
          <g transform="translate(167.7,58.799)">
            <path d="m0 0c-2.804 0-5.081 1.796-5.081 4.074 0 2.234 2.277 4.073 5.081 4.073s5.126-1.839 5.126-4.073c0-2.278-2.322-4.074-5.126-4.074m5.257-24.181h-8.761s-0.219 0.044-0.219 0.22c0 0 0.35 9.987 0.35 13.01 0 6.965-2.979 5.475-2.584 6.658 0.481 1.402 2.848 3.11 5.3 3.11 3.549 0 6.089-2.541 6.089-6.658v-16.077c0-0.219-0.175-0.263-0.175-0.263"></path>
          </g>
          <g transform="translate(196.68,40.751)">
            <path d="m0 0c0-5.3-6.308-7.14-11.302-7.14-3.898 0-10.294 1.358-10.294 5.695 0 2.146 1.708 3.986 4.249 3.986 1.446 0 3.111-0.395 3.285-0.745 0.264-0.394 0.088-0.964 0.088-1.796 0-1.752 1.095-2.672 2.585-2.672 1.62 0 2.322 0.788 2.366 1.972-0.088 2.102-3.023 2.584-6.265 3.636-2.979 1.05-5.913 3.153-5.957 6.614v0.131c0 4.556 5.388 7.14 9.856 7.14 5.125 0 10.338-1.796 10.338-5.694 0.044-2.103-1.533-3.812-4.249-3.812-1.533 0-2.979 0.483-3.154 0.789-0.219 0.35-0.044 0.92-0.044 1.796 0 1.752-0.744 2.54-2.234 2.496-1.358-0.042-2.059-1.225-2.059-2.058 0-2.147 2.585-2.716 5.607-3.592 3.504-1.008 7.184-2.76 7.184-6.746"></path>
          </g>
          <g transform="translate(205.93,47.059)">
            <path d="m0 0c3.898-0.35 5.826 1.096 5.826 3.461 0 1.183-0.964 2.19-2.322 2.102 0 0 0-0.043-0.044-0.043h-0.131c-0.307-0.043-0.701-0.175-1.052-0.394-1.445-0.876-2.102-2.98-2.277-5.126m13.755-6.352c-0.483-1.839-4.118-7.008-10.514-7.008-6.614 0-11.915 5.344-11.915 11.959 0 6.395 4.95 11.915 12.485 11.915 7.184 0 10.293-4.512 10.293-7.622 0-6.877-8.015-7.71-13.753-6.746 1.533-4.381 7.358-4.819 12.703-1.84 0.175 0.132 0.394 0.175 0.569 0.044 0.176-0.131 0.263-0.307 0.132-0.702"></path>
          </g>
          <g transform="translate(232.73,66.736)">
            <path d="m0 0c-0.128 0.032-0.253 0.067-0.379 0.103-3.676 1.048-6.622 3.821-7.911 7.388-9e-3 0.025-0.018 0.051-0.027 0.076-0.076 0.162-0.239 0.274-0.429 0.274-0.17 0-0.307-0.086-0.391-0.219-0.026-0.042-0.047-0.09-0.069-0.141-0.983-2.295-1.53-4.823-1.53-7.479 0-0.71 0.04-1.411 0.116-2.1 0.279-2.54 1.061-4.927 2.24-7.065h-2e-3c1.841-3.176 2.27-6.077 2.014-8.66-0.233-2.36-1.646-3.777-1.646-3.777s-0.324-0.556 0.211-0.783c0 0 0.068-0.041 0.155-0.077 0.094-0.037 0.187-0.078 0.283-0.114 7e-3 -2e-3 0.013-5e-3 0.02-8e-3 0.08-0.028 0.16-0.057 0.241-0.084 1.205-0.412 2.497-0.636 3.84-0.636 3.742 0 7.077 1.731 9.253 4.433 1.643 2.038 2.627 4.627 2.627 7.447 0 1.264-0.199 2.481-0.564 3.623-1.205 3.765-4.237 6.711-8.052 7.799"></path>
          </g>
          <g transform="translate(247.07,69.591)">
            <path d="m0 0c-0.012 0.03-0.027 0.059-0.04 0.088-0.08 0.149-0.232 0.251-0.413 0.251-0.138 0-0.252-0.055-0.339-0.148-0.019-0.025-0.046-0.054-0.064-0.079-2.392-3.179-3.81-7.131-3.81-11.415 0-2.54 0.5-4.963 1.404-7.178 9e-3 -0.03 0.033-0.068 0.047-0.096 0.081-0.149 0.225-0.242 0.405-0.242 0.115 0 0.304 0.081 0.39 0.205 2e-3 2e-3 4e-3 5e-3 6e-3 8e-3 2.397 3.181 3.819 7.138 3.819 11.428 0 2.54-0.501 4.963-1.405 7.178"></path>
          </g>
          <g transform="translate(245.79,90.17)">
            <path d="m0 0c-0.057 0-0.109-0.012-0.159-0.029-0.052-0.032-0.104-0.061-0.155-0.092-2.87-1.722-6.226-2.716-9.817-2.716-3.611 0-6.985 1.005-9.866 2.746-0.077 0.047-0.155 0.093-0.232 0.141-0.039 0.01-0.079 0.017-0.12 0.017-0.264 0-0.478-0.214-0.478-0.478 0-0.04 7e-3 -0.078 0.016-0.115 0.033-0.064 0.064-0.127 0.098-0.19 0.751-1.42 1.779-2.67 3.014-3.677l0.07-2e-3c2.024-0.088 3.827-1.016 5.075-2.442 0.781-0.892 1.343-1.978 1.605-3.178-0.524-0.185-0.963-0.546-1.247-1.014l3e-3 -3e-3c-4e-3 -6e-3 -8e-3 -0.011-0.012-0.017 0.517-0.437 0.97-0.946 1.346-1.511 0.276-0.416 0.509-0.862 0.695-1.334 0.145 0.37 0.32 0.724 0.521 1.061 2e-3 2e-3 4e-3 5e-3 5e-3 8e-3 0.068 0.112 0.138 0.221 0.211 0.329 0.367 0.54 0.807 1.026 1.303 1.447-1e-3 2e-3 -3e-3 5e-3 -5e-3 7e-3 5e-3 4e-3 9e-3 9e-3 0.014 0.013-0.276 0.454-0.698 0.808-1.201 0.997 0.261 1.213 0.83 2.31 1.62 3.208 1.238 1.408 3.018 2.327 5.015 2.428l0.066 1e-3c1.219 0.994 2.236 2.224 2.985 3.619l0.085 0.161c0.013 0.044 0.023 0.089 0.023 0.137 0 0.264-0.214 0.478-0.478 0.478"></path>
          </g>
          <g transform="translate(243.22,81.468)">
            <path d="m0 0c-0.073 0.048-0.154 0.043-0.226 0.06-5e-3 0.939-0.768 1.698-1.709 1.698-0.944 0-1.71-0.765-1.71-1.709 0-0.185 0.032-0.361 0.086-0.527-1.037-0.97-0.511-1.068-0.511-1.068 0.267 0.183 0.354 0.219 0.646 0.365 0.069 0.035 0.138 0.068 0.208 0.1 0.794 0.365 1.665 0.59 2.582 0.642 0.113 6e-3 0.226 9e-3 0.34 0.01 0.023 0 0.046 2e-3 0.068 2e-3 0 0 0.259-0.029 0.315 0.102 0.064 0.152 6e-3 0.247-0.056 0.3-0.011 0.01-0.022 0.018-0.033 0.025"></path>
          </g>
          <g transform="translate(228.01,81.468)">
            <path d="m0 0c0.074 0.048 0.154 0.043 0.227 0.06 5e-3 0.939 0.768 1.698 1.709 1.698 0.944 0 1.709-0.765 1.709-1.709 0-0.185-0.032-0.361-0.086-0.527 1.038-0.97 0.511-1.068 0.511-1.068-0.267 0.183-0.353 0.219-0.645 0.365-0.07 0.035-0.139 0.068-0.209 0.1-0.793 0.365-1.664 0.59-2.582 0.642-0.113 6e-3 -0.226 9e-3 -0.339 0.01-0.024 0-0.046 2e-3 -0.069 2e-3 0 0-0.258-0.029-0.314 0.102-0.065 0.152-6e-3 0.247 0.055 0.3 0.012 0.01 0.022 0.018 0.033 0.025"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoWithText;
