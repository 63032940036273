import React from "react";

const Logo = ({ className, width = "1em", height = "1em" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 195.52 167.2"
      width={width}
      height={height}
      fill="currentColor"
    >
      <defs>
        <clipPath id="clipPath20">
          <path d="m0 125.4h146.64v-125.4h-146.64z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(2.4362 0 0 -2.4362 -80.849 245.37)">
        <g clipPath="url(#clipPath20)">
          <g transform="translate(81.054,57.472)">
            <path d="m0 0c-3.242 0-5.344-2.277-5.344-3.11 0-0.92 3.81-1.095 3.81-8.892 0-4.994-1.97-6.922-3.81-6.922-1.446 0-1.797 1.359-1.797 4.206v13.491c0 0.219-0.175 0.263-0.175 0.263h-8.454s-0.175-0.044-0.175-0.219v-13.053l-6e-3 -0.485c0-2.978-1.221-4.203-2.403-4.203-1.183 0-2.315 0.917-2.366 3.549-5e-3 0.261-9e-3 0.537-0.014 0.815 7e-3 0.027 0.017 0.049 0.017 0.083l-0.044 15.245c0 4.118-3.197 6.571-6.746 6.571l-8.717-0.044c0 3.724-0.044 6.789-0.044 6.789 0.044 0.176-0.175 0.22-0.175 0.22l-8.411-3.461s-0.219 0-0.219-0.219v-3.329h-2.234s-0.175 0-0.175-0.175v-3.724c0-0.218 0.175-0.218 0.175-0.218h2.234v-12.222c0-2.76 1.183-4.775 2.979-5.87 1.139-0.657 2.497-0.964 3.899-1.008 3.154 0 6.439 1.621 7.622 3.986 0.306 0.57 0.219 1.139-0.57 1.139-1.708 0-4.424-0.788-4.993 2.76-0.044 0.438-0.088 0.876-0.088 1.402v9.813c4.097 0.348 6.493-0.564 6.568-4.758v-12.788c0-0.657 0.044-1.271 0.175-1.84 0.832-4.6 4.468-7.753 8.936-7.753 3.417 0 5.607 1.577 6.878 3.241 2.059-2.234 5.168-3.241 8.235-3.241h0.263c10.119 0 11.871 9.813 11.871 13.798 0 7.535-3.329 10.163-6.702 10.163"></path>
          </g>
          <g transform="translate(97.4,74.85)">
            <path d="m0 0c-0.127 0.032-0.253 0.067-0.378 0.103-3.676 1.048-6.623 3.821-7.911 7.388-9e-3 0.025-0.019 0.051-0.027 0.076-0.076 0.162-0.239 0.274-0.43 0.274-0.169 0-0.306-0.086-0.39-0.219-0.027-0.042-0.047-0.09-0.069-0.141-0.984-2.295-1.531-4.823-1.531-7.479 0-0.71 0.041-1.411 0.116-2.1 0.28-2.54 1.061-4.927 2.241-7.065h-2e-3c1.841-3.176 2.269-6.077 2.014-8.66-0.233-2.36-1.646-3.777-1.646-3.777s-0.324-0.556 0.211-0.783c0 0 0.067-0.041 0.154-0.077 0.095-0.037 0.188-0.078 0.283-0.114 7e-3 -2e-3 0.014-5e-3 0.021-8e-3 0.08-0.028 0.159-0.057 0.24-0.084 1.205-0.412 2.497-0.636 3.841-0.636 3.741 0 7.076 1.731 9.253 4.433 1.642 2.038 2.627 4.627 2.627 7.447 0 1.264-0.199 2.481-0.565 3.623-1.204 3.765-4.236 6.711-8.052 7.799"></path>
          </g>
          <g transform="translate(111.74,77.705)">
            <path d="m0 0c-0.012 0.03-0.028 0.059-0.04 0.088-0.081 0.149-0.232 0.251-0.413 0.251-0.137 0-0.252-0.055-0.339-0.148-0.019-0.025-0.046-0.054-0.065-0.079-2.391-3.179-3.81-7.131-3.81-11.415 0-2.54 0.501-4.963 1.405-7.178 0.01-0.03 0.033-0.068 0.048-0.096 0.08-0.149 0.223-0.242 0.405-0.242 0.114 0 0.304 0.081 0.39 0.205 2e-3 2e-3 3e-3 5e-3 5e-3 8e-3 2.397 3.181 3.819 7.138 3.819 11.428 0 2.54-0.5 4.963-1.405 7.178"></path>
          </g>
          <g transform="translate(110.46,98.284)">
            <path d="m0 0c-0.056 0-0.109-0.012-0.159-0.029-0.051-0.032-0.104-0.061-0.155-0.092-2.87-1.722-6.227-2.716-9.817-2.716-3.611 0-6.986 1.005-9.866 2.746-0.077 0.047-0.155 0.093-0.232 0.141-0.039 0.01-0.079 0.017-0.12 0.017-0.264 0-0.478-0.214-0.478-0.478 0-0.04 7e-3 -0.078 0.016-0.115 0.032-0.064 0.064-0.127 0.098-0.19 0.751-1.42 1.779-2.67 3.014-3.677l0.071-2e-3c2.022-0.088 3.826-1.016 5.074-2.442 0.781-0.892 1.343-1.978 1.605-3.178-0.524-0.185-0.963-0.546-1.247-1.014l3e-3 -3e-3c-4e-3 -6e-3 -8e-3 -0.011-0.012-0.017 0.517-0.437 0.97-0.946 1.346-1.511 0.276-0.416 0.509-0.862 0.695-1.334 0.145 0.37 0.32 0.724 0.522 1.061 1e-3 2e-3 3e-3 5e-3 5e-3 8e-3 0.067 0.112 0.137 0.221 0.21 0.329 0.367 0.54 0.807 1.026 1.303 1.447-1e-3 2e-3 -3e-3 5e-3 -5e-3 7e-3 5e-3 4e-3 9e-3 9e-3 0.014 0.013-0.276 0.454-0.698 0.808-1.202 0.997 0.262 1.213 0.831 2.31 1.621 3.208 1.238 1.408 3.018 2.327 5.015 2.428l0.066 1e-3c1.219 0.994 2.236 2.224 2.985 3.619 0.029 0.053 0.057 0.107 0.085 0.161 0.013 0.044 0.023 0.089 0.023 0.137 0 0.264-0.214 0.478-0.478 0.478"></path>
          </g>
          <g transform="translate(107.89,89.582)">
            <path d="m0 0c-0.074 0.048-0.155 0.043-0.226 0.06-6e-3 0.939-0.769 1.698-1.71 1.698-0.943 0-1.709-0.765-1.709-1.709 0-0.185 0.032-0.361 0.086-0.527-1.037-0.97-0.512-1.068-0.512-1.068 0.268 0.183 0.354 0.219 0.646 0.365 0.069 0.035 0.139 0.068 0.209 0.1 0.794 0.365 1.664 0.59 2.582 0.642 0.113 6e-3 0.226 9e-3 0.34 0.01 0.023 0 0.045 2e-3 0.068 2e-3 0 0 0.258-0.029 0.314 0.102 0.065 0.152 6e-3 0.247-0.055 0.3-0.012 0.01-0.023 0.018-0.033 0.025"></path>
          </g>
          <g transform="translate(92.677,89.582)">
            <path d="m0 0c0.074 0.048 0.155 0.043 0.227 0.06 5e-3 0.939 0.768 1.698 1.709 1.698 0.943 0 1.709-0.765 1.709-1.709 0-0.185-0.032-0.361-0.086-0.527 1.038-0.97 0.512-1.068 0.512-1.068-0.268 0.183-0.354 0.219-0.646 0.365-0.069 0.035-0.139 0.068-0.209 0.1-0.794 0.365-1.664 0.59-2.582 0.642-0.113 6e-3 -0.226 9e-3 -0.339 0.01-0.024 0-0.046 2e-3 -0.069 2e-3 0 0-0.258-0.029-0.314 0.102-0.065 0.152-6e-3 0.247 0.055 0.3 0.012 0.01 0.023 0.018 0.033 0.025"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
