import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { Helmet } from "react-helmet";
import timewiseLogo from "../../../assets/images/timewise.png";

const SEO = ({ description, title, meta = [], children }) => {
  const { language, t } = useI18next();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const metaDescription = description || t("index:description");

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={title}
      titleTemplate={`%s · ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: metaDescription,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "http://www.timewise.ch",
        "logo": "${timewiseLogo}",
        "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "customer service",
            "email": "info@timewise.ch"
          }
        }
    `}</script>
      <script type="application/ld+json">{`
        {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://www.timewise.ch/",
        "potentialAction": [{
          "@type": "SearchAction",
          "target": "https://www.timewise.ch/search/?expression={search_term_string}",
          "query-input": "required name=search_term_string"
        }]
      }
    `}</script>

      {children}
    </Helmet>
  );
};

export default SEO;
