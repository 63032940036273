import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { RecoilRoot } from "recoil";
import Footer from "../App/Footer";
import GoTop from "../../../components/App/GoTop";

const Layout = ({ children }) => {
  const { t } = useI18next();
  return (
    <RecoilRoot>
      {children}
      <GoTop scrollStepInPx="100" delayInMs="10.50" />
      <Footer />
      <CookieConsent
        location="bottom"
        style={{
          border: "2px solid #3492d1",
          borderRadius: 5,
          width: "90%",
          left: "5%",
          right: "5%",
          marginBottom: 15,
          background: "#123750",
        }}
        buttonStyle={{
          background: "#3492d1",
          color: "white",
          padding: "12px 25px 12px 25px",
          borderRadius: 5,
          fontWeight: 700,
        }}
        buttonText={t("cookieconsent:accept-label")}
        cookieName="gatsby-gdpr-google-analytics"
      >
        {t("cookieconsent:text")}
      </CookieConsent>
    </RecoilRoot>
  );
};

export default Layout;
