import classNames from "classnames";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { useRecoilState } from "recoil";
import { collapsedState } from "../../../utils/recoil-atoms";
import Logo from "./Logo";

const Navbar = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const { languages, originalPath, language: activeLanguage, t } = useI18next();

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  });

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area">
        <div className="tarn-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link
                to="/"
                onClick={() => setCollapsed(true)}
                className="navbar-brand"
              >
                <Logo
                  className="text-timewise-blue"
                  width="3.5rem"
                  height="99%"
                />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav pt-3 pt-md-0">
                  <li className="nav-item">
                    <Link to="/" activeClassName="active" className="nav-link">
                      {t("navigation:home")}{" "}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="#"
                      activeClassName="active"
                      onClick={(e) => e.preventDefault()}
                      className="nav-link"
                    >
                      {t("navigation:about-us")}{" "}
                      <i className="bx bx-chevron-down"></i>
                    </Link>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/about-us"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          {t("navigation:about-us")}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/team"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          {t("navigation:team")}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="#"
                      activeClassName="active"
                      onClick={(e) => e.preventDefault()}
                      className="nav-link"
                    >
                      {t("navigation:details")}{" "}
                      <i className="bx bx-chevron-down"></i>
                    </Link>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/how-it-works"
                          activeClassName="active"
                          className="nav-link"
                        >
                          {t("navigation:how-it-works")}{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/security"
                          activeClassName="active"
                          className="nav-link"
                        >
                          {t("navigation:security")}{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/faq"
                          activeClassName="active"
                          className="nav-link"
                        >
                          {t("navigation:faq")}{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/contact"
                      activeClassName="active"
                      className="nav-link"
                    >
                      {t("navigation:contact")}{" "}
                    </Link>
                  </li>
                </ul>

                <div className="others-option d-flex align-items-center">
                  <div className="option-item flex-grow-1">
                    <form action="/search" method="GET" className="search-box">
                      <input
                        autoComplete="off"
                        type="text"
                        name="expression"
                        className="input-search"
                        placeholder={t("search:placeholder")}
                      />
                      <button type="submit">
                        <i className="flaticon-loupe"></i>
                      </button>
                    </form>
                  </div>

                  <div className="option-item text-center">
                    <i className="bx bx-globe"></i>
                    <ul className="languages d-flex">
                      {languages.map((lng) => (
                        <li key={lng} className="px-1">
                          <Link
                            className={classNames({
                              "font-weight-bold": lng === activeLanguage,
                            })}
                            rel="alternate"
                            to={originalPath}
                            language={lng}
                          >
                            {lng}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
